import classNames from "classnames";
import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import {ProductJsonLd} from "gatsby-plugin-next-seo";
import Layout from "../../components/Layout/layout";
import Section from "../../components/Layout/Section";
import SectionTitle from "../../components/Layout/Section/Title";
import Seo from "../../components/SEO/SEO";
import ProductHero from "../../components/Product/Hero";
import Capabilities from "../../components/Tour/Capabilities";
import ProductContent from "../../components/Product/Content";
import TestimonialCard from "../../components/Testimonials/Card";
import FreeDemoForm from "../../components/Form/FreeDemo";
import Link from "../../components/UI/Link";
import * as pageStyles from "./product-page.module.css";

const ContentGradient = ({type}) => {
    return (
        <div className={pageStyles.productPage__gradientWrapper}>
            {type === "outsource" && (
                <>
                    <StaticImage
                        src="../../assets/images/product/outsource/content-gradient_mobile.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        className={`${pageStyles.productPage__gradient} ${pageStyles.productPage__gradient_mobile}`}
                    />
                    <StaticImage
                        src="../../assets/images/product/outsource/content-gradient_tablet.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        className={`${pageStyles.productPage__gradient} ${pageStyles.productPage__gradient_tablet}`}
                    />
                    <StaticImage
                        src="../../assets/images/product/outsource/content-gradient_desktop.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        className={`${pageStyles.productPage__gradient} ${pageStyles.productPage__gradient_desktop}`}
                    />
                </>
            )}
            {type === "customer" && (
                <>
                    <StaticImage
                        src="../../assets/images/product/customer/content-gradient_mobile.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        className={`${pageStyles.productPage__gradient} ${pageStyles.productPage__gradient_mobile}`}
                    />
                    <StaticImage
                        src="../../assets/images/product/customer/content-gradient_tablet.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        className={`${pageStyles.productPage__gradient} ${pageStyles.productPage__gradient_tablet}`}
                    />
                    <StaticImage
                        src="../../assets/images/product/customer/content-gradient_desktop.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        className={`${pageStyles.productPage__gradient} ${pageStyles.productPage__gradient_desktop}`}
                    />
                </>
            )}
            {type === "support" && (
                <>
                    <StaticImage
                        src="../../assets/images/product/support/content-gradient_mobile.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        className={`${pageStyles.productPage__gradient} ${pageStyles.productPage__gradient_mobile}`}
                    />
                    <StaticImage
                        src="../../assets/images/product/support/content-gradient_tablet.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        className={`${pageStyles.productPage__gradient} ${pageStyles.productPage__gradient_tablet}`}
                    />
                    <StaticImage
                        src="../../assets/images/product/support/content-gradient_desktop.png"
                        alt=""
                        placeholder="none"
                        layout="fixed"
                        className={`${pageStyles.productPage__gradient} ${pageStyles.productPage__gradient_desktop}`}
                    />
                </>
            )}
        </div>
    );
};

const ProductPage = ({data: {product}}) => {
    const {seo, capabilities} = product;

    return (
        <Layout className={classNames([pageStyles.productPage, pageStyles[`productPage_${product.type}`]])}>
            <Seo seo={seo} />
            <ProductJsonLd name={product.title} description={product.description} brand="ITSM 365" />
            <ProductHero
                title={product.title}
                description={product.description}
                freeDaysCount={product.freeDaysCount}
                type={product.type}
                variant={product.heroVariant}
                media={product.heroMedia}
            />
            {!!capabilities && capabilities.enabled && (
                <Capabilities
                    sectionTitle={capabilities.title || ""}
                    capabilityItems={capabilities.items || []}
                    description=""
                />
            )}
            <ProductContent content={product.content} gradient={<ContentGradient type={product.type} />} />
            <Section>
                <div className={pageStyles.productPage__testimonials}>
                    <div className={pageStyles.productPage__testimonialsHeader}>
                        <SectionTitle>Что наши клиенты говорят о данном продукте</SectionTitle>
                        <Link
                            to="/reviews"
                            withArrow
                            state={{activeProductSlug: product.slug}}
                            className={pageStyles.productPage__testimonialsLink}
                        >
                            Все отзывы
                        </Link>
                    </div>
                    <div className={pageStyles.productPage__testimonialsList}>
                        {product.testimonials.slice(0, 2).map((data, idx) => (
                            <TestimonialCard key={idx} {...data} />
                        ))}
                    </div>
                    <Link
                        to="/reviews"
                        withArrow
                        state={{activeProductSlug: product.slug}}
                        className={`${pageStyles.productPage__testimonialsLink} ${pageStyles.productPage__testimonialsLink_mobile}`}
                    >
                        Все отзывы
                    </Link>
                </div>
            </Section>
            <FreeDemoForm />
        </Layout>
    );
};

export const pageQuery = graphql`
    query ($id: String) {
        product: strapiProducts(id: {eq: $id}) {
            seo {
                title
                description
                openGraph {
                    title
                    description
                    image {
                        url
                    }
                }
            }
            slug
            title
            shortTitle
            description: short_description
            type
            freeDaysCount
            content {
                id
                title
                description
                type
                appStoreURL
                googlePlayURL
                item {
                    id
                    title
                    description
                    media {
                        id
                        youtubeVideoURL
                        content {
                            mime
                            width
                            height
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(placeholder: NONE)
                                }
                            }
                        }
                    }
                }
                media {
                    id
                    youtubeVideoURL
                    content {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                }
            }
            testimonials {
                testimonial
                author
                logo {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                }
            }
            heroVariant
            heroMedia {
                youtubeVideoURL
                content {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                }
            }
            capabilities {
                enabled
                title
                items {
                    id
                    title
                    description
                    icon {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default ProductPage;
