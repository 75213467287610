// extracted by mini-css-extract-plugin
export var productHero = "product-hero-module--product-hero--2FZP5";
export var productHero__title = "product-hero-module--product-hero__title--ee59k";
export var productHero__desc = "product-hero-module--product-hero__desc--0dqka";
export var productHero__media = "product-hero-module--product-hero__media--FBa+U";
export var productHero__button = "product-hero-module--product-hero__button--jWcx2";
export var productHero__gradient = "product-hero-module--product-hero__gradient--T+niC";
export var productHero__gradient_mobile = "product-hero-module--product-hero__gradient_mobile--PFLOt";
export var productHero__gradient_desktop = "product-hero-module--product-hero__gradient_desktop--Q5ZsJ";
export var productHero__gradient_tablet = "product-hero-module--product-hero__gradient_tablet--5bzeB";
export var productHero_textLeft = "product-hero-module--product-hero_text-left--pqgP+";