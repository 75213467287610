import * as React from "react";
import Quotes from "../../assets/svg/quotes.svg";
import Media from "../Common/Media";
import * as cardStyles from "./testimonial-card.module.css";

const TestimonialCard = ({testimonial, author, logo}) => {
    return (
        <div>
            <Quotes className={cardStyles.testimonialCard__icon} />
            <blockquote>
                <p className={cardStyles.testimonialCard__content}>{testimonial}</p>
                <footer>
                    <cite className={cardStyles.testimonialCard__author}>{author}</cite>
                </footer>
            </blockquote>
            <Media media={{content: logo, youtubeVideoURL: ""}} className={cardStyles.testimonialCard__logo} />
        </div>
    );
};

export default TestimonialCard;
