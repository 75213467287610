// extracted by mini-css-extract-plugin
export var productPage = "product-page-module--product-page--+R5PZ";
export var productPage_support = "product-page-module--product-page_support--XD8le";
export var productPage_outsource = "product-page-module--product-page_outsource--TKWly";
export var productPage_customer = "product-page-module--product-page_customer--zfT43";
export var productPage__gradient = "product-page-module--product-page__gradient--2RaSn";
export var productPage__gradient_mobile = "product-page-module--product-page__gradient_mobile--LN1aT";
export var productPage__gradient_desktop = "product-page-module--product-page__gradient_desktop--Aqp5O";
export var productPage__gradient_tablet = "product-page-module--product-page__gradient_tablet--XKEwH";
export var productPage__gradientWrapper = "product-page-module--product-page__gradient-wrapper--h6UT9";
export var productPage__testimonials = "product-page-module--product-page__testimonials--bXVUp";
export var productPage__testimonialsHeader = "product-page-module--product-page__testimonials-header--KJHk5";
export var productPage__testimonialsList = "product-page-module--product-page__testimonials-list--qOtAq";
export var productPage__testimonialsLink_mobile = "product-page-module--product-page__testimonials-link_mobile--06FAo";
export var productPage__testimonialsLink = "product-page-module--product-page__testimonials-link--S0r7M";