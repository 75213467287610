import classNames from "classnames";
import {StaticImage} from "gatsby-plugin-image";
import PropTypes from "prop-types";
import {useCallback} from "react";
import * as React from "react";
import {pluralize} from "../../utils/number";
import Section from "../Layout/Section";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import Button from "../UI/Button";
import * as heroStyles from "./product-hero.module.css";

const ProductHero = ({title, description, freeDaysCount, type, variant, media}) => {
    const scrollToForm = useCallback(() => {
        const form = document.querySelector("#free-demo-form");
        if (!form) {
            return;
        }

        form.scrollIntoView({behavior: "smooth"});
    }, []);

    return (
        <Section>
            <div
                className={classNames({
                    [heroStyles.productHero]: true,
                    [heroStyles.productHero_textLeft]: variant === "text_left",
                })}
            >
                <h1 className={heroStyles.productHero__title}>
                    <TemplateString template={title} />
                </h1>
                <p className={heroStyles.productHero__desc}>{description}</p>
                <Button type="button" className={heroStyles.productHero__button} onClick={scrollToForm}>
                    {pluralize(freeDaysCount, ["%d день", "%d дня", "%d дней"])} бесплатно
                </Button>{" "}
                {media && (
                    <div className={heroStyles.productHero__media}>
                        <Media media={media} big={variant !== "text_left"} imageProps={{loading: "eager"}} />
                    </div>
                )}{" "}
                {type === "outsource" && (
                    <>
                        <StaticImage
                            src="../../assets/images/product/outsource/hero-gradient_mobile.png"
                            alt=""
                            placeholder="none"
                            layout="fixed"
                            loading="eager"
                            className={`${heroStyles.productHero__gradient} ${heroStyles.productHero__gradient_mobile}`}
                        />
                        <StaticImage
                            src="../../assets/images/product/outsource/hero-gradient_tablet.png"
                            alt=""
                            placeholder="none"
                            layout="fixed"
                            loading="eager"
                            className={`${heroStyles.productHero__gradient} ${heroStyles.productHero__gradient_tablet}`}
                        />
                        <StaticImage
                            src="../../assets/images/product/outsource/hero-gradient_desktop.png"
                            alt=""
                            placeholder="none"
                            layout="fixed"
                            loading="eager"
                            className={`${heroStyles.productHero__gradient} ${heroStyles.productHero__gradient_desktop}`}
                        />
                    </>
                )}{" "}
                {type === "customer" && (
                    <>
                        <StaticImage
                            src="../../assets/images/product/customer/hero-gradient_mobile.png"
                            alt=""
                            placeholder="none"
                            layout="fixed"
                            loading="eager"
                            className={`${heroStyles.productHero__gradient} ${heroStyles.productHero__gradient_mobile}`}
                        />
                        <StaticImage
                            src="../../assets/images/product/customer/hero-gradient_tablet.png"
                            alt=""
                            placeholder="none"
                            layout="fixed"
                            loading="eager"
                            className={`${heroStyles.productHero__gradient} ${heroStyles.productHero__gradient_tablet}`}
                        />
                        <StaticImage
                            src="../../assets/images/product/customer/hero-gradient_desktop.png"
                            alt=""
                            placeholder="none"
                            layout="fixed"
                            loading="eager"
                            className={`${heroStyles.productHero__gradient} ${heroStyles.productHero__gradient_desktop}`}
                        />
                    </>
                )}{" "}
                {type === "support" && (
                    <>
                        <StaticImage
                            src="../../assets/images/product/support/hero-gradient_mobile.png"
                            alt=""
                            placeholder="none"
                            layout="fixed"
                            loading="eager"
                            className={`${heroStyles.productHero__gradient} ${heroStyles.productHero__gradient_mobile}`}
                        />
                        <StaticImage
                            src="../../assets/images/product/support/hero-gradient_tablet.png"
                            alt=""
                            placeholder="none"
                            layout="fixed"
                            loading="eager"
                            className={`${heroStyles.productHero__gradient} ${heroStyles.productHero__gradient_tablet}`}
                        />
                        <StaticImage
                            src="../../assets/images/product/support/hero-gradient_desktop.png"
                            alt=""
                            placeholder="none"
                            layout="fixed"
                            loading="eager"
                            className={`${heroStyles.productHero__gradient} ${heroStyles.productHero__gradient_desktop}`}
                        />
                    </>
                )}
            </div>
        </Section>
    );
};

ProductHero.defaultProps = {
    variant: "text_above",
};

ProductHero.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    freeDaysCount: PropTypes.number.isRequired,
    type: PropTypes.oneOf(["customer", "outsource", "support"]),
    variant: PropTypes.string,
    media: PropTypes.shape({
        youtubeVideoURL: PropTypes.string,
        content: PropTypes.exact({
            url: PropTypes.string.isRequired,
            mime: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
            localFile: PropTypes.exact({
                childImageSharp: PropTypes.exact({
                    gatsbyImageData: PropTypes.object,
                }),
            }),
        }),
    }),
};

export default ProductHero;
